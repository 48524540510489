export const rolesObj = {
    USER: "USER",
    ADMIN: "ADMIN",
    COMPANY: "COMPANY",
    EMPLOYEE: "EMPLOYEE",
}
export const ROLE_ADMIN = 'ADMIN'
export const businessTypeObj = {
    MANUFACTURER: "MANUFACTURER",
    IMPORTER: "IMPORTER",
    EXPORTER: "EXPORTER",
    WHOLESALER: "WHOLESALER",
    RESELLER: "RESELLER",
    OTHERS: "OTHERS",
}


export const businessNatureObj = {

    PROPRIETOR: "PROPRIETOR",
    PARTNERSHIP: "PARTNERSHIP",
    PRIVATELTDCO: "PRIVATE LTD CO.",
    PUBLICLTDCO: "PUBLIC LTD CO.",
    ONEPERSONCOMPANY: "ONE PERSON COMPANY",
    OTHERS: "OTHERS",
}

