import logo from "../../assets/images/logo1.png";
import favicon from "../../assets/images/favicon.png";
import avatar from "../../assets/images/avatar.png";
import brand from "../../assets/images/brand.jpg";
import product from "../../assets/images/product.jpeg";
import customer from "../../assets/images/customer.jpg";
import cardLogo from "../../assets/images/card.png";
import card2Logo from "../../assets/images/card2.png";
import checkCircle from "../../assets/images/check-circle.png";
export const images = {
  product,
  brand,
  logo,
  favicon,
  avatar,
  customer,
  cardLogo,
  card2Logo,
  checkCircle
};
