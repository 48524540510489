import { url } from "../../services/url.service";

export const generateFilePath = (fileName) => {
  return `${url}/uploads/${fileName}`;
};

export const addRupeeSymbol = (price) => {
  return `₹${price}`;
};

export const MainOrderStatus = {
  ORDERED: "ORDERED",
  CONFIRMED: "CONFIRMED",
  DISPATCHED: "DISPATCHED",
  DELIVERED: "DELIVERED",
  CANCELLED: "CANCELLED",
};

export const MainOrderStatusArr = [
  {
    label: "ORDERED",
    value: "ORDERED",
  },
  {
    label: "CONFIRMED",
    value: "CONFIRMED",
  },
  {
    label: "DISPATCHED",
    value: "DISPATCHED",
  },
  {
    label: "DELIVERED",
    value: "DELIVERED",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
  },
];

export const schemeType = {
  BUY_GET_PRODUCT: "BUY_GET_PRODUCT",
  BUY_GET_DISCOUNT: "BUY_GET_DISCOUNT",
};

export const schemeTypeArr = [
  {
    label: "BUY_GET_PRODUCT",
    value: "BUY_GET_PRODUCT",
  },
  {
    label: "BUY_GET_DISCOUNT",
    value: "BUY_GET_DISCOUNT",
  },
];

export const discountType = {
  AMOUNT_OFF: "AMOUNT_OFF",
  PERCENTAGE_OFF: "PERCENTAGE_OFF",
};

export const discountTypeArr = [
  {
    label: "AMOUNT_OFF",
    value: "AMOUNT_OFF",
  },
  { label: "PERCENTAGE_OFF", value: "PERCENTAGE_OFF" },
];

export const productStatusArr = [
  { label: "Published", value: "Publish" },
  { label: "Pending", value: "Pending" },
];

export const productMainStatusArr = [
  { label: "Active", value: "Active" },
  { label: "Disabled", value: "Disabled" },
];

export const productStatusObj = { Published: "Publish", Pending: "Pending" };
export const productMainStatusObj = { Active: "Active", Disabled: "Disabled" };

export const filterStaticArr = [
  { label: "Product Title", value: "ProductTitle" },
  { label: "Product Type", value: "ProductType" },
  { label: "Product Category", value: "ProductCategory" },
  { label: "Product Vendor", value: "ProductVendor" },
  { label: "Product Tag", value: "ProductTag" },
  { label: "Selling Price ", value: "SellingPrice" },
  { label: "MRP", value: "MRP" },
  { label: "Weight", value: "Weight" },
];

export const filterConditionArr = [
  { label: "is equal to", value: "isEqualTo" },
  { label: "Is Not Equal To", value: "IsNotEqualTo" },
  { label: "is greater than", value: "IsGreaterThan" },
  { label: "is less than", value: "IsLessThan" },
  { label: "startsWith", value: "startsWith" },
  { label: "endsWith", value: "endsWith" },
  { label: "contains", value: "contains" },
  { label: "does not contain", value: "doesNotContain" },
  { label: "is not empty", value: "isNotEmpty" },
  { label: "is  empty", value: "isEmpty" },
];

export const categorySortFilter = [
  { label: "Bestselling", value: "Bestselling" },
  { label: "A-Z", value: "A-Z" },
  { label: "Z-A", value: "Z-A" },
  { label: "Highest Price", value: "Highest Price" },
  { label: "Lowest Price", value: "Lowest Price" },
  { label: "Newest", value: "Newest" },
  { label: "Oldest", value: "Oldest" },
];

export const categorySortFilterObj = {
  Bestselling: "Bestselling",
  A_Z: "A-Z",
  Z_A: "Z-A",
  Highest_Price: "Highest Price",
  Lowest_Price: "Lowest Price",
  Newest: "Newest",
  Oldest: "Oldest",
};

export const Order_Delivery_Filter_Arr = [
  {
    label: "delhivery",
    value: "delhivery",
  },
];

export const Order_Delivery_Filter_Obj = {
  Shiprocket: "DELHIVERY",
};

export const Order_Payment_Filter_Arr = [
  {
    label: "COD",
    value: "COD",
  },
  {
    label: "PREPAID",
    value: "PREPAID",
  },
];

export const Order_Payment_Filter_Obj = {
  COD: "COD",
  PREPAID: "PREPAID",
};

export const Order_Payment_Status_Filter_Arr = [
  {
    label: "PAID",
    value: "PAID",
  },
  {
    label: "Unpaid",
    value: "Unpaid",
  },
];

export const Order_Payment_Status_Filter_Obj = {
  PAID: "PAID",
  UNPAID: "Unpaid",
};

export const filterCaseArr = [
  {
    label: "AND",
    value: "AND",
  },
  { label: "OR", value: "OR" },
];
