import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/verification";


export const addVerification = (formData) => {
  return axiosApiInstance.post(serverUrl + "/addVerification", formData);
};

export const getVerification = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getVerification?${query}`);
};
export const updateVerification = (id, obj) => {
  return axiosApiInstance.patch(`${serverUrl}/updateVerification/${id}`, obj);
};



export const getBySlug = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getBySlug/${id}`);
};
export const deleteVerification = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
