import React, { useEffect, useState } from "react";
import { DashboardBox } from "../Utility/DashboardBox";
import { useSearchParams } from "react-router-dom";
import { getBySlug, updateVerification } from "../../services/verification.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";
import CustomButton from "../Utility/Button";

function ShowVerfication() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [verficationobj, setVerficationobj] = useState("");
  useEffect(() => {
    if (searchParams.get("id")) {
      HandleGetUsers(searchParams.get("id"))
     
    }
    // return () => { handleDeleteSelectedUser(); handleEdit({}) }
  }, [searchParams.get("id")])


  const HandleCardUserAdd = async () => {
    try {
      
     let obj = {
      isVerfiy:true
      }
      if (searchParams.get("id")) {
        let { data: res } = await updateVerification(verficationobj?._id, obj)
        if (res.message) {
          toastSuccess(res.message);
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }

  const HandleGetUsers = async (id) => {
    try {

   


      let { data: res } = await getBySlug(id)
      if (res.data) {
        setVerficationobj(res.data)
      }
    }
    catch (err) {
      toastError(err)
    }
  }

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">Verfication </h5>
          <DashboardBox>
            <ul className="blue-1 fs-14 customer-profile p-3">
              <li>
                <span className="fw-600">
                  Name<span>:</span>
                </span>
                {verficationobj?.name}
              </li>
              <li>
                <span className="fw-600">
                  Document Type<span>:</span>
                </span>
                {verficationobj?.documentType}
              </li>

              <li>
                <span className="fw-600">
                  Document<span>:</span>
                </span>
                <img src={generateFilePath(verficationobj?.image)} />
              </li>

              <li>
                <span className="fw-600">
                  Designation<span>:</span>
                </span>
                {verficationobj?.designation}
              </li>
              <li>
                <span className="fw-600">
                  Brand<span>:</span>
                </span>
                {verficationobj?.brand}
              </li>
              <li>
                <span className="fw-600">
                  Links<span>:</span>
                </span>
                {verficationobj && verficationobj?.linkArr?.length > 0 && verficationobj?.linkArr.map((el) => (
                  <p>{el.link}</p>
                ))}
              </li>


              
            </ul>
                {
                  verficationobj?.isVerfiy ?    <CustomButton greenBtn={true} btnName={'Verified'} />
                : (
                  <CustomButton
                  isBtn
                  btntype={"button"}
                  ClickEvent={() => HandleCardUserAdd()}
                  iconName={ "fa-solid fa-check"}
                  btnName={searchParams.get("id") ? "VERIFY" : "ADD"}
                />
                  )
                }
         
          </DashboardBox>
        </div>
      </section>
    </main>
  );
}

export default ShowVerfication;
