import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { images } from "../Images/Images";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { generalModelStatuses } from "../Utility/constants";
// import moment from "moment/moment";
import SearchBox from "../Utility/SearchBox";
import { useSelector, useDispatch } from "react-redux";
import {
  CouponAdd,
  CouponGet,
  CouponUpdate,
  SetCouponObj,
  CouponDelete,
} from "../../redux/actions/Coupon/Coupon.actions";
import { toastError } from "../Utility/ToastUtils";
import { Switch } from "@mui/material";

function Coupon() {
  let dispatch = useDispatch();
  const [name, setName] = useState("");
  const [discountType, setDiscountType] = useState("percentage")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState(0);
  const [limit, setLimit] = useState("");
  const [value, setVlaue] = useState(0);
  const [couponsArr, setCouponsArr] = useState([]);
  const [displayCouponArr, setDisplayCouponArr] = useState([]);
  const [couponId, setCouponId] = useState(null);
  const [isWebsite, setisWebsite] = useState(false);

  const couponArr = useSelector(
    (state) => state.coupon.coupons
  );
  console.log(couponArr, "couponArr23");
  const couponObj = useSelector(
    (state) => state.coupon.couponsObj
  );

  console.log(couponObj, "couponObj jsx");

  useEffect(() => {
    if (couponArr?.length) {
      setCouponsArr(couponArr);
      setDisplayCouponArr(couponArr);
    }
  }, [couponArr]);

  const handleCouponGet = () => {
    dispatch(CouponGet());
  };
  useEffect(() => {
    handleCouponGet();
  }, []);
  const handleCategoryDelete = (row) => {
    let confirm =window.confirm("Do you really want to delete this item?")
    if (confirm) {
      dispatch(CouponDelete(row));
    }
  
  };

  useEffect(() => {
    if (couponObj && couponObj._id) {
      setName(couponObj?.name);
    //   setStartDate(moment(couponObj?.startDate).format('YYYY-MM-DD'));
    //   setEndDate(moment(couponObj?.endDate).format('YYYY-MM-DD'));
      setDiscountType(couponObj?.discountType);
      setMinAmount(couponObj?.minAmount);
      setMaxAmount(couponObj?.maxAmount);
      setLimit(couponObj?.limit);
      setVlaue(couponObj?.value);
      setCouponId(couponObj?._id);
      setisWebsite(couponObj?.isWebsite);

    } else {
      setName("");
      setStartDate("");
      setEndDate("");
      setDiscountType("percentage");
      setMinAmount("");
      setLimit(0);
      setMaxAmount(0);
      setVlaue("");
      setCouponId(null);
      setCouponId(false);
    }

    // return () => {
    //   dispatch(SetCouponObj(null));
    // };
  }, [couponObj]);

  const handleCategoryEdit = (row) => {
    dispatch(SetCouponObj(row));
  };


  
  const coupon_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Discount Name",
      selector: (row) => row.name,
    },
    {
        name: "Discount Type",
        selector: (row) => row.discountType,
    },
    {
      name: "status",
      button: true,
      cell: (row) => <Switch onChange={(e) => handleChangeActiveStatus(row._id, !row.status)} checked={row.status} />,
      width: "10%",
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      button: true,
      cell: (row) => (
        <ActionIcon
          remove
          edit
          Uniquekey={row.id}
          deletePath="/Coupon/Coupon"
          onDeleteClick={() => {
            // console.log(row, "delete");
            handleCategoryDelete(row._id);
          }}
          isRedirected={true}
          onEditClick={() => {
            console.log(row, "asdf");
            handleCategoryEdit(row);
          }}
          editPath="/Coupon/Coupon"
        />
      ),
    },
  ];


  const handleChangeActiveStatus = async (id, value) => {
    dispatch(CouponUpdate(id, {status:value}));
  };

  const handleSubmit = (e) => {
    e.preventDefault();


    if(`${name}` == ''){
      toastError('name is mandatory');
      return
    }

    if(`${startDate}` == ''){
      toastError('start Date is mandatory');
      return
    }
    if(`${endDate}` == ''){
      toastError('end Date is mandatory');
      return
    }

    if(`${discountType}` == ''){
      toastError('discountType is mandatory');
      return
    }
    if(`${minAmount}` == ''){
      toastError('minmum Amount is mandatory');
      return
    }



    if(`${value}` == ''){
      toastError('value is mandatory');
      return
    }
 
    if(discountType == 'percentage') {
      if(value > 100 || value <=0) {
        toastError("Please enter value 0 between 100");
        return 0
      }

      if(!maxAmount  && maxAmount==0) {
        toastError("Please enter max amount ");
        return 0
      }
    
    }

    let obj = {
      name,
      startDate,
      endDate,
      discountType,
      minAmount,
      maxAmount,
      limit,
      value,
      isWebsite
    };

    console.log(couponObj,"couponIdcouponId")
    if (couponObj && couponObj?._id) {
      console.log(obj, "drt");
      dispatch(CouponUpdate(couponObj?._id, obj));
    } else {
      dispatch(CouponAdd(obj));
    }
    dispatch(SetCouponObj(null));
  };
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <h5 className="blue-1 mb-4">Add Coupon</h5>
              <DashboardBox>
                <form action="" className="form row">
                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      Discount NAME<span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Discount NAME"
                      className="form-control"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                    Discount TYPE
                    <span className="red">*</span>
                    </label>
                    <div>
                    <input 
                     type="radio" 
                     value="percentage"
                     name="discountType"
                     checked={discountType=='percentage'?true:false}
                     onChange={(event) => setDiscountType(event.target.value)}
                    /> Percentage Off &nbsp;&nbsp;&nbsp;
                    <input 
                    type="radio" 
                    value="flat" 
                    name="discountType" 
                    checked={discountType=='flat'?true:false}
                    onChange={(event) => setDiscountType(event.target.value)}
                    /> Flat Off
                    </div>
                  </div>
                  
                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      Discount VALUE<span className="red">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Discount value"
                      className="form-control"
                      value={value}
                      min={0}
                      onChange={(event) => setVlaue(event.target.value)}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      Start Date <span className="red">*</span>
                    </label>
                    <input
                      type="date"
                      placeholder="Coupon Start date"
                      className="form-control"
                    //   min={moment(new Date()).format('YYYY-MM-DD')}
                      value={startDate}
                      onChange={(event) => setStartDate(event.target.value)}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      End Date <span className="red">*</span>
                    </label>
                    <input
                      type="date"
                      placeholder="Coupon End date"
                      className="form-control"
                      value={endDate}
                    //   min={moment(new Date()).format('YYYY-MM-DD')}
                      onChange={(event) => setEndDate(event.target.value)}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      No of Coupons <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Coupon Used Limit"
                      className="form-control"
                      name="limit"
                      value={limit}
                      onChange={(event) => setLimit(event.target.value)}
                    />
                  </div>
                {
                  discountType == 'percentage' &&  (
                    <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                    Max Amount of Discount<span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Minimum Amount"
                      className="form-control"
                      name="minAmount"
                      value={maxAmount}
                      onChange={(event) => setMaxAmount(event.target.value)}
                    />
                  </div>
                  )
                }
               
                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                    Minimum Amount of Purchase <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Minimum Amount"
                      className="form-control"
                      name="minAmount"
                      value={minAmount}
                      onChange={(event) => setMinAmount(event.target.value)}
                    />
                  </div>
                  <div className="col-6">
                      <label>Is Website</label>
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                          checked={isWebsite}
                          onChange={() => setisWebsite(!isWebsite)}
                          className="form-check-input"
                          type="checkbox"
                          name="category-status"
                          value="option1"
                          id="active-banner"
                        />
                        <label
                          className="form-check-label fs-14"
                          htmlFor="active-banner"
                        >
                          Active
                        </label>
                      </div>
                      </div>
                  <div className="col-12">
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="ADD"
                      ClickEvent={handleSubmit}
                    />
                  </div>
                </form>
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex justify-content-between mb-2 align-items-center">
                <h5 className="blue-1 m-0">Coupon List</h5>
                <SearchBox extraClass="bg-white" />
              </div>
              <DashboardTable>
                <DataTable
                  columns={coupon_columns}
                  // data={blog_category_data}
                  data={
                    couponsArr && couponsArr.length > 0
                      ? couponsArr
                      : []
                  }
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Coupon;
