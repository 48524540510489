import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill"; // ES6
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { AddModal, ImageSelectModal } from "../../Utility/Modal";
import { useSelector, useDispatch } from "react-redux";
import { getAllNestedCategories } from "../../../redux/actions/Category/Category.actions";
import { BrandGet } from "../../../redux/actions/Brand/brand.actions";
import { PRODUCTAdd } from "../../../redux/actions/Product/Product.actions";
import { ATTRIBUTEGet } from "../../../redux/actions/Attribute/Attribute.actions";
import { usersGet } from "../../../redux/actions/Users/users.actions";
import { rolesObj } from "../../../utils/roles";
import { getMediaManagerImages } from "../../../services/MediaManager.service";
import { toastError } from "../../Utility/ToastUtils";
import { DISCOUNT_TYPE, FLASH_SALE_CONSTANT, discountType } from "../../Utility/constants";
import MultiFileUpload from "../../Utility/MultipleFileUpload";
import { getFilterData } from "../../../services/homepage.service";
function GeneralProduct() {
  const dispatch = useDispatch();

  const [mediaModal, setMediaModal] = useState(false);
  const [mediaVariotionModal, setMediaVariotionModal] = useState(false);
  const [mediaMainModal, setMediaMainModal] = useState(false);
  const [mediaModalCurrentIndex, setMediaModalCurrentIndex] = useState(0);

  const [internalCode, setInternalCode] = useState("");
  const [productCode, setProductCode] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [mainImageAlt, setMainImageAlt] = useState("");

  //////product details
  const [name, setName] = useState("");
  const [vendorSku, setVendorSku] = useState("");
  const [despcription, setDespcription] = useState("");
  const [details, setDetails] = useState("");
  const [material, setMaterial] = useState("");
  const [price, setPrice] = useState(0);  
  const [discount, setDiscount] = useState(0);  

  const [discountType, setDiscountType] = useState({
    label:DISCOUNT_TYPE.FLATOFF,
    value:DISCOUNT_TYPE.FLATOFF,
  });
  const [stock, setStock] = useState(0);


  const [occasionCategory, setOccasionCategory] = useState({
    label:"",
    value:"",
  });

const [productDisplayType, setproductDisplayType] = useState([
  {label:"None",value:""},
  {label:"Hot Deals",value:"hot_deals"},
  {label:"Featured Product",value:"featured_product"},
  {label:"New Arrivals",value:"new_arrival"},
  {label:"BestSeller",value:"bestseller"},
]);


  const [productDisplay, setproductDisplay] = useState({
    label:"None",
    value:""
  });


  const [genderType, setgenderType] = useState([
    {label:"None",value:""},
    {label:"Male",value:"male"},
    {label:"Female",value:"female"},
    {label:"Kids",value:"kids"},
  ]);
  const [gender, setgender] = useState({
    label:"None",
    value:""
  });

  const [isSale, setisSale] = useState(false);
  const [isDiscount, setisDiscount] = useState(false);
  const [isNew, setisNew] = useState(false);
  const [imageArr, setImageArr] = useState([]);
  //////size of product
  const [product_dimension_width, setProduct_Dimension_width] = useState(0);
  const [product_dimension_height, setProduct_Dimension_height] = useState(0);
  const [product_dimension_length, setProduct_Dimension_length] = useState(0);
  const [product_dimension_weight, setProduct_Dimension_weight] = useState(0);


  ///////tags
  const [tags, setTags] = useState("");
  //////brand
  const [selectedBrandId, setSelectedBrandId] = useState("");
  //////category
  const [mainCategoryArr, setMainCategoryArr] = useState([]);
  const [mainAttributesArr, setMainAttributesArr] = useState([]);
  const [subAttributesArr, setSubAttributesArr] = useState([]);
  ///////////////////////////////////////////////////////////////
  const [ModalBox, setModalBox] = useState(false);
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  ///////////////////////////////////////////////////////////////
  const [videoLink, setVideoLink] = useState("");
  const [productImageStr, setProductImageStr] = useState("");
  const [specificationFile, setSpecificationFile] = useState("");
  const [displayAttributesArr, setDisplayAttributesArr] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImage, setMetaImage] = useState("");
  const [status, setStatus] = useState("Publish");
  const [selectedCategoryArr, setSelectedCategoryArr] = useState([]);
  const [selectedFilterArr, setSelectedFilterArr] = useState([]);

  ///////////////////////////////////////////////////////////////
  const authUser = useSelector((state) => state.auth);
  const brands = useSelector((state) => state.brand.brands);
  const categoryArr = useSelector((state) => state.category.categories);
  const productObj = useSelector((state) => state.product.productObj);
  const attributes = useSelector((state) => state.attribute.attributes);
  const [selectedSellerObj, setSelectedSellerObj] = useState(null);
  const userArr = useSelector((state) => state.users.users);
  const [isSeller, setIsSeller] = useState(false);
  const [filterArr, setfilterArr] = useState([])
  const [mainfilterArr, setMainfilterArr] = useState([])
  const [mediaArr, setMediaArr] = useState([]);
  const getSellerUsers = () => {
    if (authUser.role == rolesObj.SELLER) {
      setIsSeller(true);
    } else {
      dispatch(usersGet("role=SELLER"));
    }
  };


  const handleCategoryChecked = (arr) => {
    if (arr.length > 0 && arr) {
      return arr.map((el) => {
        if (el.subCategoryArr && el.subCategoryArr.length > 0 && el.checked) {
          let tempArr = selectedCategoryArr;
          if (tempArr.some((el) => el != el._id)) {
            tempArr.push(el._id);
          }
          setSelectedCategoryArr([...tempArr]);
          return { ...el, categoryId: el._id, subCategoryArr: handleCategoryChecked(el.subCategoryArr) };
        } else {
          if (el.checked) {
            let tempArr = selectedCategoryArr;
            if (tempArr.some((el) => el != el._id)) {
              tempArr.push(el._id);
            }
            setSelectedCategoryArr([...tempArr]);
            return { ...el, categoryId: el._id };
          }
        }
      });
    } else {
      return arr;
    }
  };

  const handleSubmit = () => {
    let cat_arr = returnSelectedCategories(mainCategoryArr);
  
    let tempCategoryArr = handleCategoryChecked(mainCategoryArr);
    let tempmainfilterArr = mainfilterArr.filter((filt) => filt.checked==true).map((el) =>({...el,optionArr:el.optionArr.filter((op) =>op.checked ==true)}))
    // let tempCategoryArr = handleFilterChecked(mainCategoryArr)
    console.log(selectedCategoryArr, "selectedCategoryArr");
    console.log(tempmainfilterArr, "tempmainfilterArr");
    // console.log(selectedCategoryArr, "selectedCategoryArr")
    let obj = {
      categoryArr: cat_arr.map((el) => {
        return { categoryId: el._id };
      }),
      productCode:internalCode,
      name,
      createdBy: authUser?.user?._id,
      sku: vendorSku,
      description: despcription,
      price,
      stock,
      isSale,
      isNew,
      mainImage,
      filterArr:tempmainfilterArr,
      imageArr: imageArr,
      product_dimension_width,
      product_dimension_height,
      product_dimension_length,
      product_dimension_weight,
      tags,
      discount,
      isDiscount,

      discountType:discountType.value,
      attributesArr: displayAttributesArr,
      videoLink: videoLink ? videoLink : "",
      status,
      metaTitle,
      metaDescription,
      metaImage,
      productDisplay:productDisplay.value,
      gender:gender.value
    };
    console.log(obj, "send Obj");
    dispatch(PRODUCTAdd(obj));
  };

  useEffect(() => {
    dispatch(getAllNestedCategories());
    // dispatch(BrandGet());
    getSellerUsers();
    dispatch(ATTRIBUTEGet());
  }, []);

  useEffect(() => {
    if (categoryArr && categoryArr.length > 0) {
      setMainCategoryArr([...categoryArr]);
    }
  }, [categoryArr]);

  useEffect(() => {
    if (attributes && attributes.length > 0) {
      setMainAttributesArr([...attributes]);
    }
  }, [attributes]);

  const returnSelectedCategories = (arr) => {
    let new_selected_arr = arr.filter((el) => el.checked);
    let subCategories = arr.reduce((acc, el) => [...acc, ...el.subCategoryArr.filter((el) => el.checked)], []);
    if (subCategories?.length) {
      return [...new_selected_arr, ...returnSelectedCategories(subCategories)];
    } else {
      return [...new_selected_arr];
    }
  };

  const handleMainAttributeSelection = (obj) => {
    // console.log(obj);
    if (obj?.attributeValueArr && obj?.attributeValueArr.length > 0) {
      setSubAttributesArr(obj.attributeValueArr);
    }
  };

  const handleBrandSelection = (obj) => {
    // console.log(obj);
    setSelectedBrandId(obj?._id);
  };

  const handleFileSet = (value,index) => {
    if(value && value.length > 0){
      let tempArr = imageArr;
      let tempImageArr =  value.map((img) => ({image:img.base64}))
      const children = tempArr.concat(tempImageArr);
      setImageArr([...children]);
    } else {
      let tempArr = imageArr;
      tempArr[index].image = value;
      setImageArr([...tempArr]);
    }
  };
  const handleproductImageAltEntry = (value, index) => {
    // console.log(value, index);
    let tempArr = imageArr;
    tempArr[index].imageAlt = value;
    setImageArr([...tempArr]);
  };

  const handlePdfFileSet = (value) => {
    // console.log(value);
    setSpecificationFile(value);
  };

  const handleAttributeVariantChange = (index) => {
    let tempArr = [...mainAttributesArr];
    tempArr[index].checked = !tempArr[index].checked;
    // console.log(tempArr, "sub attribute");
    setMainAttributesArr(tempArr);
  };

  const handleInnerAttributeVariantChange = (index, innerIndex) => {
    let tempArr = [...mainAttributesArr];
    // console.log(tempArr, tempArr[index].attributeValueArr[innerIndex].checked, tempArr[index].attributeValueArr[innerIndex]);
    tempArr[index].attributeValueArr[innerIndex].checked = !tempArr[index].attributeValueArr[innerIndex].checked;
    // console.log(tempArr, "sub attribute");
    setMainAttributesArr([...tempArr]);
    generateCombinations(tempArr);
  };

  ////////////p n c generator
  const generateCombinations = (tempattributeArr) => {
    // console.log(tempattributeArr, "tempCHECKV");
    const combine = ([head, ...[headTail, ...tailTail]]) => {
      // https://stackoverflow.com/a/57015870
      if (!headTail) {
        return head?.map((el) => ({
          name: el.name,
          attributeValueArr: [...el.attributeValueArr],
        }));
      }
      const combined = headTail?.reduce((acc, x) => {
        return acc.concat(
          head.map((h) => ({
            name: `${h.name}-${x.name}`,
            attributeValueArr: [...h.attributeValueArr, ...x.attributeValueArr],
            price: 0,
          }))
        );
      }, []);
      return combine([combined, ...tailTail]);
    };
    let finalArr = [];
    tempattributeArr.forEach((el) => {
      if (el.checked) {
        let tempArr = el.attributeValueArr.filter((elx) => elx.checked);
        if (tempArr.length) {
          finalArr.push(
            tempArr.map((el) => ({
              name: el.name,
              attributeValueArr: [
                {
                  attributeId: el._id,
                },
              ],
              price: 0,
              stock: 0,
              imagesArr:[]
            }))
          );
        }
      }
    });
    // console.log(finalArr, "finalArr");
    if (finalArr.length) {
      // console.log("finalarr", finalArr);
      finalArr = combine(finalArr);
      // setCombinationArr([...finalArr])
      // console.log("combinations", finalArr);
    } else {
      setDisplayAttributesArr([]);
      // setCombinationArr = []
    }

    // console.log(finalArr, "FINAL ARR");
    // finalArr
    if (finalArr.length) {
      // console.log(el)
      setDisplayAttributesArr([
        ...finalArr.map((el) => ({
          ...el,
          price: 0,
          stock: 0,
          imagesArr:[]
        })),
      ]);
    }
  };


  const handleDisplayAttributesArrImageChange = (value, index) => {


    console.log(value,"valuevaluevaluevalue")

  

    let tempArr = [...displayAttributesArr];
    if(value && value.length > 0)
    {
    
    let temoImageArr = tempArr[index]["imagesArr"] ;
      let tempImageArr =  value.map((img) => ({image:img.base64,name:""}))
      const children = temoImageArr.concat(tempImageArr);
      tempArr[index]["imagesArr"] = children
      setDisplayAttributesArr([...tempArr]);
     }
    else  {
      let temoImageArr = tempArr[index]["imagesArr"] ;
      temoImageArr.push({
        image:value,
        name:""
      })
      
       tempArr[index]["imagesArr"] = temoImageArr
       setDisplayAttributesArr([...tempArr]);
    } 
    

    }


    const handleMultipleImagesRemove = (atrIndex,index) => {
      let tempArr = [...displayAttributesArr];
      let temoImageArr = tempArr[atrIndex]["imagesArr"] ;
      console.log(temoImageArr,"temoImageArr")
          temoImageArr =temoImageArr.filter((el, indexxxx) => indexxxx != index)

          console.log(temoImageArr,"temoImageArr")
          tempArr[atrIndex]["imagesArr"] = temoImageArr
          setDisplayAttributesArr([...tempArr]);
  };

  const handleDisplayAttributesArrChange = (e, index, key) => {
    let tempArr = [...displayAttributesArr];
    console.log(key,"temoImageArrtemoImageArrtemoImageArr")
       tempArr[index][key] = e

    setDisplayAttributesArr([...tempArr]);
  };

  const handleRenderNestedCategory = (arr, id, value) => {
    let tempArr = arr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          handleRenderNestedCategory(el.subCategoryArr, id, value);
        } else {
          return el;
        }
      }
    });
    return tempArr;
  };


  
  const handleRenderNestedFilter = (arr, id, value) => {
    let tempArr = arr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.optionArr && el.optionArr.length > 0) {
          handleRenderNestedFilter(el.optionArr, id, value);
        } else {
          return el;
        }
      }
    });
    return tempArr;
  };

  const handleImageObjAdd = () => {
    let tempArr = imageArr;
    tempArr.push({ image: "", imageAlt: "" });
    // console.log(tempArr, "asdas");
    setImageArr([...tempArr]);
  };
  const handleImageObjRemove = (inde) => {
    let tempArr = imageArr.filter((el, index) => index != inde);
    setImageArr([...tempArr]);
  };

  const handleNestedCategoryCheckBoxEvent = (id, value) => {
    let tempCategoryArr = categoryArr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          el.subAttributesArr = handleRenderNestedCategory(el.subCategoryArr, id, value);
          return el;
        } else {
          return el;
        }
      }
    });
    setMainCategoryArr([...tempCategoryArr]);
  };



  const handleNestedFilterCheckBoxEvent = (id, value) => {
    let tempCategoryArr = filterArr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.optionArr && el.optionArr.length > 0) {
          el.optionArr = handleRenderNestedFilter(el.optionArr, id, value);
          return el;
        } else {
          return el;
        }
      }
    });
    console.log(tempCategoryArr,"tempCategoryArrtempCategoryArrtempCategoryArr")
    setMainfilterArr([...tempCategoryArr]);
  };


  const handleRenderCheckboxCategory = (obj) => {
    return (
      <div className="col-12 mb-3" style={{ marginLeft: `${obj.level + 5}px` }}>
        <input className="form-check-input pointer" checked={obj.checked} value={tags} onChange={(event) => handleNestedCategoryCheckBoxEvent(obj._id, event.target.checked)} type="checkbox" />
        <label style={{ paddingLeft: 5 }}>{obj.name}</label>
        {obj.checked &&
          obj.subCategoryArr &&
          obj.subCategoryArr.length > 0 &&
          obj.subCategoryArr.map((el) => {
            return handleRenderCheckboxCategory(el);
          })}
      </div>
    );
  };

  const handleRenderCheckboxFilter = (obj) => {
    return (
      <div className="col-12 mb-3" style={{ marginLeft: `15px` }}>
        <input className="form-check-input pointer" checked={obj.checked} value={tags} onChange={(event) => handleNestedFilterCheckBoxEvent(obj._id, event.target.checked)} type="checkbox" />
        <label style={{ paddingLeft: 5 }}>{obj.heading ?obj.heading :obj.title}</label>
        {obj.checked &&
          obj.optionArr &&
          obj.optionArr.length > 0 &&
          obj.optionArr.map((el) => {
            return handleRenderCheckboxFilter(el);
          })}
      </div>
    );
  };
  return (
    <form className="form">
      <div className="row">
        <div className="col-12 col-md-8">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Product Information</h5>

              <div className="col-12 col-md-12 mb-3">
                <label>
                  Product Name <span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Product Code <span className="red">*</span></label>
                <input value={internalCode} onChange={(event) => setInternalCode(event.target.value)} type="text" className="form-control" />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label>
                  PRODUCT SKU <span className="red">*</span>
                </label>
                <input value={vendorSku} onChange={(event) => setVendorSku(event.target.value)} type="text" className="form-control" />
              </div>
     
              <div className="col-12 col-md-4 mb-3">
                <label>
                  Price<span className="red">*</span>
                </label>
                <input value={price} onChange={(event) => setPrice(event.target.value)} type="text" className="form-control" />
              </div>

              <div className="col-12 col-md-4 mb-3">
                <label>
                    Discount Type  <span className="red">*</span>
                </label>
                <Select value={discountType} options={Object.values(DISCOUNT_TYPE).map(ele => ({ label: ele, value: ele }))} onChange={(val) => setDiscountType(val)} />
              </div>
           
              <div className="col-12 col-md-4 mb-3">
                <label>
                  Discount  <span className="red">*</span>
                </label>
                <input value={discount} onChange={(event) => setDiscount(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  Stock  <span className="red">*</span>
                </label>
                <input value={stock} onChange={(event) => setStock(event.target.value)} type="text" className="form-control" />
              </div>
              
           

              <div className="col-12 col-md-6 mb-3">
                <label>
                    Product Display  <span className="red">*</span>
                </label>
                <Select value={productDisplay} options={productDisplayType} onChange={(val) => setproductDisplay(val)} />
              </div>
          

              <div className="col-12 col-md-6 mb-3">
                <label>Sale</label>
                <div className="row">
                  <div className="col-3">
                    <input id="isSale" value={true} onChange={(event) => setisSale(event.target.value)} type="radio" className="form-check-input pointer" name={"isSale"} />
                    <label style={{ paddingLeft: 10 }} htmlFor="isSale">
                      Yes
                    </label>
                  </div>
                  <div className="col-3">
                    <input id="isSale1" value={false} onChange={(event) => setisSale(event.target.value)} name={"isSale"} className="form-check-input pointer" type="radio" />
                    <label style={{ paddingLeft: 10 }} htmlFor="isSale1">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>New</label>
                <div className="row">
                  <div className="col-3">
                    <input id="setisNew" value={true} checked={isNew == true} onChange={(event) => setisNew(true)} type="radio" className="form-check-input pointer" name={"setisNew1"} />
                    <label style={{ paddingLeft: 10 }} htmlFor="setisNew">
                      Yes
                    </label>
                  </div>
                  <div className="col-3">
                    <input id="setisNew1" value={false} checked={isNew == false} onChange={(event) => setisNew(true)} type="radio" className="form-check-input pointer" name={"setisNew1"} />
                    <label style={{ paddingLeft: 10 }} htmlFor="setisNew1">
                      No
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-6 mb-3">
                <label>Made To Order</label>
                <div className="row">
                  <div className="col-3">
                    <input id="setIsMadeToOrder" value={true} checked={isMadeToOrder == true} onChange={(event) => setIsMadeToOrder(true)} type="radio" className="form-check-input pointer" name={"setIsMadeToOrder1"} />
                    <label style={{ paddingLeft: 10 }} htmlFor="setIsMadeToOrder">
                      Yes
                    </label>
                  </div>
                  <div className="col-3">
                    <input id="setIsMadeToOrder1" value={false} checked={isMadeToOrder == false} onChange={(event) => setIsMadeToOrder(false)} name={"setIsMadeToOrder1"} className="form-check-input pointer" type="radio" />
                    <label style={{ paddingLeft: 10 }} htmlFor="setIsMadeToOrder1">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Ships In (Days)</label>
                <input value={ships_in_days} onChange={(event) => setShips_in_days(event.target.value)} type="number" className="form-control" />
              </div> */}
            
                <div className="col-12 mb-3">
                <label>Tags (Comma Separated)</label>
                <input value={tags} onChange={(event) => setTags(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Product Category</h5>
              {mainCategoryArr &&
                mainCategoryArr.length > 0 &&
                mainCategoryArr.map((el) => {
                  return handleRenderCheckboxCategory(el);
                })}
            </div>
          
              <div className="col-12 col-md-3 mb-3">
              <h5 className="blue-1 mb-4">Product Attributes</h5>
             
                {mainAttributesArr &&
                  mainAttributesArr.length > 0 &&
                  mainAttributesArr.map((el, index) => {
                    return (
                      <div key={el._id}>
                        <input checked={el.checked} onChange={(event) => handleAttributeVariantChange(index)} className="form-check-input pointer" type="checkbox" />
                        <label style={{ paddingLeft: 5 }}>{el.name}</label>

                        {el.checked && (
                          <>
                            {el.attributeValueArr.length > 0 &&
                              el.attributeValueArr.map((ele, index2) => {
                                return (
                                  <div key={index2} style={{ marginLeft: 20 }}>
                                    <input checked={ele.checked} onChange={(event) => handleInnerAttributeVariantChange(index, index2)} className="form-check-input pointer" type="checkbox" />
                                    <label style={{ paddingLeft: 5 }}>{ele.label}</label>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </div>
                    );
                  })}
              </div>
              {displayAttributesArr && displayAttributesArr.length > 0 && (
                <div className="col-12 mb-0">
                  <h5 className="blue-1 mb-4">Product Variants Info</h5>
                </div>
              )}
              {displayAttributesArr &&
                displayAttributesArr.length > 0 &&
                displayAttributesArr.map((el, index) => {
                  return (
                    <div className="col-12 col-md-12 " key={index}>
                      <div className="d-flex align-items-baseline justify-content-between">
                        <label>
                          {el.name}
                          <span className="red">*</span>
                        </label>
                      </div>
                      <div className="row">
                      <div className="col-12 col-md-4 mb-3">
                        <label>
                          Stock<span className="red">*</span>{" "}
                        </label>
                        <input value={el.stock} onChange={(e) => handleDisplayAttributesArrChange(e.target.value, index, "stock")} type="number" className="form-control" />
                      </div>
                      <div className="col-12 col-md-4 mb-3">
                        <label>
                          Variant Price <span className="red">*</span>
                        </label>
                        <input value={el.price} onChange={(e) => handleDisplayAttributesArrChange(e.target.value, index, "price")} type="number" className="form-control" />
                      </div>
                      <div className="col-12 col-md-4 mb-3">
                      <label>
                          Images <span className="red">*</span><br/>
                        </label>
                        <MultiFileUpload
                            onFileChange={(val) => handleDisplayAttributesArrImageChange(val,index)} />
                       
                      
                      </div>
                      <div className="row ">
                  {el.imagesArr &&
                    el.imagesArr.length > 0 &&
                    el.imagesArr.map((imgel, indexxxx) => {
                      return (
                          <div className="col-md-1  ml-1 position-relative" key={indexxxx}>
                           
                           
                              <img
                                src={imgel.image}
                                width="50px"
                                height="50px"
                              />
                            
                           <button type="button" className="btn btn-sm btn-danger btn-absolute "    onClick={() => handleMultipleImagesRemove(index,indexxxx)}  >
                                <i className="fa fa-close"></i>
                            </button> 
                          </div>
                     
                      );
                    })}

                     
                </div>
                      </div>
                   
                    </div>
                  );
                })}
             
             
            </div>
           
            <div className="border-bottom pb-3 mb-4 row">
              <div className="col-12 mb-0">
                <h5 className="blue-1 mb-4">Weight Height Info</h5>
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Weight [Gm]</label>
                <input value={product_dimension_weight} onChange={(event) => setProduct_Dimension_weight(event.target.value)} type="number" className="form-control" />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Length [Cm]</label>
                <input value={product_dimension_length} onChange={(event) => setProduct_Dimension_length(event.target.value)} type="number" className="form-control" />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Breadth [Cm]</label>
                <input value={product_dimension_width} onChange={(event) => setProduct_Dimension_width(event.target.value)} type="number" className="form-control" />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Height [Cm]</label>
                <input type="number" value={product_dimension_height} onChange={(event) => setProduct_Dimension_height(event.target.value)} className="form-control" />
              </div>
            </div>
         
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Description</h5>
              <div className="col-12 mb-3">
                <ReactQuill theme="snow" value={despcription} onChange={(e) => setDespcription(e)} />
              </div>
            </div>
                
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Details</h5>
              <div className="col-12 mb-3">
                <ReactQuill theme="snow" value={details}  onChange={(e) => setDetails(e)} />
              </div>
            </div>

            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Material</h5>
              <div className="col-12 mb-3">
                <ReactQuill theme="snow" value={material}  onChange={(e) => setMaterial(e)} />
              </div>
            </div>

            <div className="row">
              <h5 className="blue-1 mb-4">SEO info</h5>
              <div className="col-12 mb-3">
                <label>META TITLE</label>
                <input onChange={(e) => setMetaTitle(e.target.value)} value={metaTitle} type="text" className="form-control" />
              </div>
              <div className="col-12 mb-3">
                <label>META DESCRIPTION</label>
                <textarea onChange={(e) => setMetaDescription(e.target.value)} value={metaDescription} name="META DESCRIPTION" className="form-control" rows="3"></textarea>
              </div>
              <div className="col-12 mb-3">
                <label>META IMAGE (300X300)PX</label>
                <FileUpload onFileChange={(val) => setMetaImage(val)} />
              </div>
              <div className="col-12">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
              </div>
            </div>
          </DashboardBox>
        </div>
        <div className="col-12 col-md-4">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-3">Product Image Info</h5>
              <div className="row">
                    <div className="col-12 mb-3">
                      <label>Main Image </label>         
                      
                      <FileUpload onFileChange={(val) => setMainImage(val)} />
                       {/* <CustomButton
                            isBtn
                            iconName="fa-solid fa-circle-plus"
                            btnName="Add Image"
                            changeClass="btn btn-black"
                            ClickEvent={(e) => {
                              e.preventDefault();
                              setMediaMainModal(true);
                           
                            }}
                          /><br/> */}
                    {mainImage && mainImage && <img style={{ height: 100,width:100 }} src={mainImage} alt="" />}
          
                    </div>

                    <div className="col-12 mb-3">
                <label>Alt Image</label>
                <input onChange={(e) => setMainImageAlt(e.target.value)} value={mainImageAlt} type="text" className="form-control" />
              </div>
                {/* <div className="col-2 me-5">
                  <CustomButton btntype="button" ClickEvent={handleImageObjAdd} isBtn noIcon btnName="+" />
                </div>
                <div className="col-2">
                  <CustomButton btntype="button" ClickEvent={handleImageObjRemove} isBtn noIcon btnName="-" />
                </div> */}
              </div>
              <div className="col-12 mb-3">
                <label>
                 Multiple Product Image
                 <MultiFileUpload
                            onFileChange={(val) => handleFileSet(val,0)} />
              
                </label>

                {imageArr &&
                  imageArr.length > 0 &&
                  imageArr.map((el, index) => {
                    return (
                      <div key={index} style={{ marginTop: 20, borderBottom: "grey 1px solid", paddingBottom: 15 }} className="row">
                        <div className="col-12">
                          <label>
                             Image {index+1}     <button type="button" className="btn btn-sm btn-danger "    onClick={() => handleImageObjRemove(index)}  >
                                <i className="fa fa-close"></i>
                            </button>
                          </label>
                       
                          <div className="row">{el?.image && el.image && <img style={{ height: 200 }} src={el.image} alt="" />}</div>
                        </div>

                        <div className="col-12">
                          <label>
                            Product Image alt<span className="red">*</span>
                          </label>
                          <input onChange={(e) => handleproductImageAltEntry(e.target.value, index)} value={el?.imageAlt} type="text" className="form-control" />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            {/* <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-3">Product Video Link</h5>
              <div className="col-12 mb-3">
                <label>Video Link</label>
                <input onChange={(e) => setVideoLink(e.target.value)} type="text" className="form-control" />
              </div>
            </div> */}

            {/* <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-3">Pdf Specifications</h5>
              <div className="col-12 mb-3">
                <label>PDF SPECIFICATIONS</label>
                <FileUpload onFileChange={handlePdfFileSet} />
              </div>
            </div> */}
            {/* <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-3">Product Video Info</h5>
              <div className="col-12 mb-3">
                <label>VIDEO PROVIDER</label>
                <Select options={options} />
              </div>
              <div className="col-12 mb-3">
                <label>VIDEO LINK</label>
                <input type="url" className="form-control" />
              </div>
            </div> */}
            <div className="row">
              <h5 className="blue-1 mb-3">Others Info</h5>
              <div className="col-12 mb-3">
                <label>
                  STATUS<span className="red">*</span>
                </label>
                <div className="d-flex">
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input onChange={(e) => setStatus("Publish")} checked={status == "Publish"} className="form-check-input pointer" type="radio" name="product-status" value="option1" id="product-publish" />
                    <label className="form-check-label fs-14 pointer" htmlFor="product-publish">
                      Publish
                    </label>
                  </div>
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input onChange={(e) => setStatus("Pending")} checked={status == "Pending"} className="form-check-input pointer" type="radio" name="product-status" value="option2" id="product-pending" />
                    <label className="form-check-label fs-14 pointer" htmlFor="product-pending">
                      Pending
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </DashboardBox>
        </div>
      </div>
    </form>
  );
}

export default GeneralProduct;
