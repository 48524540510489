import axios from 'axios';
import { axiosApiInstance } from '../App';
import { url } from "./url.service";
const serverUrl = url + '/connection';
  export const addaddLinkApi = (cardId,forData) => {
    return axiosApiInstance.post(serverUrl + `/addLink/${cardId}`,forData);
  };
  export const getInsightsConnectionApi = (cardId,query) => {
    return axiosApiInstance.get(serverUrl + `/getInsightsConnection/${cardId}?${query}`);
  };
  export const addConnectionBycCardApi = (cardId,formData) => {
    return axiosApiInstance.post(serverUrl + `/addConnectionBycCard/${cardId}`,formData);
  };
