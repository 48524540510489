import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CATEGORYGet } from "../../redux/actions/Category/Category.actions";
import { generalModelStatuses } from "../Utility/constants";
import { DashboardBox } from "../Utility/DashboardBox";
import Select from "react-select";
import lodash from "lodash";
import CustomButton from "../Utility/Button";
import { toastError, toastSuccess } from "../../utils/toastUtils";

import FileUpload from "../Utility/FileUpload";
import { generateFilePath } from "../Utility/utils";
import { addHomepageData, gethomepageData } from "../../services/homepage.service";
import { PRODUCTGet } from "../../redux/actions/Product/Product.actions";

function Homepage() {
  const dispatch = useDispatch();
  const categoryArrFromRedux = useSelector(
    (state) => state.category.categories
  );
  const productArr = useSelector((state) => state.product.products);


  const [name, setName] = useState("");

  const [mainCategoryArr, setMainCategoryArr] = useState([]);
  const [mainProductArr, setMainProductArr] = useState([]);

  const [section1Heading, setSection1Heading] = useState("");
  const [section1BoldHeading, setSection1BoldHeading] = useState("");
  const [section1Description, setSection1Description] = useState("");

  const [backendData, setBackendData] = useState({
    htype:"home",
    section1: {
      heading: "",
      description: "",
      categoryArr: [],
    },
    section2: {
      heading: "",
      description: "",
      productsArr: [
        {
          productId: "",
        },
      ],
    },
    section3: {
      heading: "",
      image: "",
      mobileImage: "",
      url: "",
    },
    section4: {
      heading: "",
      description: "",
      productsArr: [
        {
          productId: "",
        },
      ],
    },
    section5: {
      heading: "",
      subHeading: "",
      sectionArr: [
        {
          name: "",
          image: "",
          mobileImage: "",
          url: "",
        },
        {
          name: "",
          image: "",
          mobileImage: "",

          url: "",
        },
        {
          name: "",
          image: "",
          mobileImage: "",

          url: "",
        },
      ],
    },
    section6: {
      heading: "",
      subHeading: "",
      sectionArr: [
        {
          name: "",
          image: "",
          mobileImage: "",

          url: "",
        },
        {
          name: "",
          image: "",
          mobileImage: "",

          url: "",
        },
      ],
    },
    section7: {
      heading: "",
      description: "",
      productsArr: [
        {
          productId: "",
        },
      ],
    },
    section8: {
      heading: "",
      subHeading: "",
      sectionArr: [
        {
          name: "",
          image: "",
          mobileImage: "",

          url: "",
        },
        {
          name: "",
          image: "",
          mobileImage: "",

          url: "",
        },
        {
          name: "",
          image: "",
          mobileImage: "",

          url: "",
        },
        {
          name: "",
          image: "",
          mobileImage: "",

          url: "",
        },
      ],
    },
    section9: {
      heading: "",
      subHeading: "",
      sectionArr: [
        {
          name: "",
          image: "",
          mobileImage: "",

          url: "",
        },
        {
          name: "",
          image: "",
          mobileImage: "",

          url: "",
        },
        {
          name: "",
          image: "",
          mobileImage: "",

          url: "",
        },
        {
          name: "",
          image: "",
          mobileImage: "",

          url: "",
        },
        {
          name: "",
          image: "",
          mobileImage: "",

          url: "",
        },
      ],
    },
    section10: {
      heading: "",
      subHeading: "",
      sectionArr: [
        {
          name: "",
          image: "",
          mobileImage: "",
          url: "",
        },
        {
          name: "",
          image: "",
          mobileImage: "",
          url: "",
        },
        {
          name: "",
          image: "",
          mobileImage: "",
          url: "",
        },
        {
          name: "",
          image: "",
          mobileImage: "",
          url: "",
        },
      ],
    },
    // section11: {
    //   heading: "",
    //   subHeading: "",
    // },
    // section12: {
    //   heading: "",
    //   buttonText: "",
    // },
  });

  const handleGetHomepage = async () => {
    try {
      let { data: res } = await gethomepageData('htype=home');
      if (res.data) {
        let sectioData = res.data;
        sectioData.htype = 'home';
        
        // sectioData.section1.categoryArr = sectioData.section1?.categoryArr.map(
        //   (el) => ({ ...el, value: el.categoryId })
        // );
        setBackendData(sectioData);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGetHomepage();
  }, []);

  useEffect(() => {
    dispatch(CATEGORYGet("level=1"));
    let query = `skip=0&limit=1000`;

    dispatch(PRODUCTGet(query));
  }, []);

  // useEffect(() => {
  //   if (categoryArrFromRedux) {
  //     setMainCategoryArr([...categoryArrFromRedux]);
  //   }
  // }, [categoryArrFromRedux]);

  useEffect(() => {
    if (productArr) {
      setMainProductArr([...productArr]);
    }
  }, [productArr]);

  const handleSetValueOfBackendData = (value, key, key2, key3, key4) => {
    let backendDataTemp = backendData;
    console.log(
      value,
      key,
      key2,
      key3,
      key4,
      " backendDataTemp[key][key2] backendDataTemp[key][key2]"
    );
    if (key2 == "productArr") {
      let tempcatArr = value.map((el) => ({ ...el, productId: el.value }));
      backendDataTemp[key][key2] = tempcatArr;
    } else if (key2 == "sectionArr") {
      let tempcatArr = [...backendDataTemp[key][key2]];

      console.log(tempcatArr, "tempcatArr1");
      tempcatArr[key3][key4] = value;
      console.log(tempcatArr, "tempcatArr");

      backendDataTemp[key][key2] = [...tempcatArr];
    } else {
      backendDataTemp[key][key2] = value;
    }

    setBackendData({ ...backendDataTemp });
  };

  const handleSubmit = async () => {
    try {
      let obj = backendData;
      let { data: res } = await addHomepageData(obj);
      if (res.success) {
        toastSuccess(res.message);
      }
    } catch (err) {
      toastError(err);
    }
  };
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">Hompage</h5>
          <form className="form">
            <div className="row my-4">
              <div className="col-12 col-md-12 mb-0">
                <DashboardBox>
                  <div className="row my-4">
                
                  <div className="row my-4">
                  

                    <h5 className="blue-1 mb-4">Section 1</h5>
                    <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData.section1.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section1",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>

                    <div className="col-12">
                      <label>
                        Caption <span className="red">*</span>
                      </label>
                      <input
                        value={backendData.section1.caption}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section1",
                            "caption"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12">
                      <label>
                        Url <span className="red">*</span>
                      </label>
                      <input
                        value={backendData.section1.url}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section1",
                            "url"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12">
                      <label>
                        Description <span className="red">*</span>
                      </label>
                      <textarea
                        value={backendData.section1.description}
                        rows={5}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section1",
                            "description"
                          )
                        }
                        type="text"
                        className="form-control"
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <label>Image</label>
                      <br />

                      {backendData.section1.image != "" ? (
                        backendData.section1.image?.includes("base64") ? (
                          <img
                            src={backendData.section1.image}
                            width={200}  alt="No  Found"
                            height={100}
                          />
                        ) : (
                          <img
                            src={generateFilePath(backendData.section1.image)}      width={200}  alt="No  Found"
                            height={100}
                          />
                        )
                      ) : (
                        ""
                      )}
                      <FileUpload
                        onFileChange={(val) =>
                          handleSetValueOfBackendData(val, "section1", "image")
                        }
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="row my-4">
                  <h5 className="blue-1 mb-4">Section 2</h5>
                  <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData?.section2.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section2",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                
                    <div className="col-12">
                      <label>
                        Products to be displayed in this section
                        <span className="red">*</span>
                      </label>

                      <div>
                        <Select
                          value={backendData.section2.productArr}
                          isMulti
                          onChange={(event) =>
                            handleSetValueOfBackendData(
                              event,
                              "section2",
                              "productArr",
                              "multi",
                              "productId"
                            )
                          }
                          options={
                            mainProductArr &&
                            mainProductArr.length > 0 &&
                            mainProductArr.map((el) => ({
                              label: el.name,
                              value: el._id,
                            }))
                          }
                        />
                      </div>
                    </div>
                  
                    {/* <div className="col-12">
                      <label>Mobile Image</label>
                      <br />

                      {backendData.section3.mobileImage != "" ? (
                        backendData.section3.mobileImage?.includes("base64") ? (
                          <img
                            src={backendData.section3.mobileImage}
                            width={200}  alt="No  Found"
                            height={100}
                          />
                        ) : (
                          <img
                            src={generateFilePath(backendData.section3.mobileImage)}      width={200}  alt="No  Found"
                            height={100}
                          />
                        )
                      ) : (
                        ""
                      )}
                      <FileUpload
                        onFileChange={(val) =>
                          handleSetValueOfBackendData(val, "section3", "mobileImage")
                        }
                      />
                    </div> */}
                  </div>
                  <hr />
                  <div className="row my-4">
                  <h5 className="blue-1 mb-4">Section 3</h5>
                  <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData.section3.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section3",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>

                    
                    <div className="col-12">
                      <label>
                        Caption <span className="red">*</span>
                      </label>
                      <input
                        value={backendData.section3.caption}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section3",
                            "caption"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12">
                      <label>
                        Url <span className="red">*</span>
                      </label>
                      <input
                        value={backendData.section3.url}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section3",
                            "url"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12">
                      <label>
                        Description <span className="red">*</span>
                      </label>
                      <textarea
                        value={backendData.section3.description}
                        rows={5}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section3",
                            "description"
                          )
                        }
                        type="text"
                        className="form-control"
                      ></textarea>
                    </div>
                   
                    <div className="col-12">
                      <label>Image</label>
                      <br />

                      {backendData.section3.image != "" ? (
                        backendData.section3.image?.includes("base64") ? (
                          <img
                            src={backendData.section3.image}
                            width={200}  alt="No  Found"
                            height={100}
                          />
                        ) : (
                          <img
                            src={generateFilePath(backendData.section3.image)}      width={200}  alt="No  Found"
                            height={100}
                          />
                        )
                      ) : (
                        ""
                      )}
                      <FileUpload
                        onFileChange={(val) =>
                          handleSetValueOfBackendData(val, "section3", "image")
                        }
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="row my-4">
                  <h5 className="blue-1 mb-4">Section 4</h5>
                  <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData?.section4.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section4",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                
                    <div className="col-12">
                      <label>
                        Products to be displayed in this section
                        <span className="red">*</span>
                      </label>

                      <div>
                        <Select
                          value={backendData.section4.productArr}
                          isMulti
                          onChange={(event) =>
                            handleSetValueOfBackendData(
                              event,
                              "section4",
                              "productArr",
                              "multi",
                              "productId"
                            )
                          }
                          options={
                            mainProductArr &&
                            mainProductArr.length > 0 &&
                            mainProductArr.map((el) => ({
                              label: el.name,
                              value: el._id,
                            }))
                          }
                        />
                      </div>
                    </div>
                
                    {/* <div className="col-12">
                      <label>Mobile Image</label>
                      <br />

                      {backendData.section3.mobileImage != "" ? (
                        backendData.section3.mobileImage?.includes("base64") ? (
                          <img
                            src={backendData.section3.mobileImage}
                            width={200}  alt="No  Found"
                            height={100}
                          />
                        ) : (
                          <img
                            src={generateFilePath(backendData.section3.mobileImage)}      width={200}  alt="No  Found"
                            height={100}
                          />
                        )
                      ) : (
                        ""
                      )}
                      <FileUpload
                        onFileChange={(val) =>
                          handleSetValueOfBackendData(val, "section3", "mobileImage")
                        }
                      />
                    </div> */}
                  </div>
                  <hr />
                  </div>
            
          
                   {/*     <hr />
                
                  <hr />
                  <div className="row my-4">
                    <h5 className="blue-1 mb-4">Section 5</h5>
                    <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData.section5.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section5",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>

                    {backendData.section5.sectionArr &&
                      backendData.section5.sectionArr.length > 0 &&
                      backendData.section5.sectionArr.map((sect, seINdex) => (
                        <>
                          <div className="col-4">
                            <label>
                              Name <span className="red">*</span>
                            </label>
                            <input
                              value={sect.name}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                  event.target.value,
                                  "section5",
                                  "sectionArr",
                                  seINdex,
                                  "name"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-4">
                            <label>
                              URL <span className="red">*</span>
                            </label>
                            <input
                              value={sect.url}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                  event.target.value,
                                  "section5",
                                  "sectionArr",
                                  seINdex,
                                  "url"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-4">
                            <label>Image</label>
                            <br />

                            {sect.image != "" ? (
                              sect.image?.includes("base64") ? (
                                <img
                                  src={sect.image}
                                  width={200}  alt="No  Found"
                                  height={100}
                                />
                              ) : (
                                <img src={generateFilePath(sect.image)}      width={200}  alt="No  Found"
                                height={100} />
                              )
                            ) : (
                              ""
                            )}
                            <FileUpload
                              onFileChange={(val) =>
                                handleSetValueOfBackendData(
                                  val,
                                  "section5",
                                  "sectionArr",
                                  seINdex,
                                  "image"
                                )
                              }
                            />
                          </div>
                        </>
                      ))}
                  </div>
                  <hr />
                  <div className="row my-4">
                    <h5 className="blue-1 mb-4">Section 6</h5>

                    {backendData.section6.sectionArr &&
                      backendData.section6.sectionArr.length > 0 &&
                      backendData.section6.sectionArr.map((sect, seINdex) => (
                        <>
                          <div className="col-6">
                            <label>
                              URL <span className="red">*</span>
                            </label>
                            <input
                              value={sect.url}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                    event.target.value,
                                  "section6",
                                  "sectionArr",
                                  seINdex,
                                  "url"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-6">
                            <label>Image</label>
                            <br />

                            {sect.image != "" ? (
                              sect.image?.includes("base64") ? (
                                <img
                                  src={sect.image}
                                  width={200}  alt="No  Found"
                                  height={100}
                                />
                              ) : (
                                <img src={generateFilePath(sect.image)}      width={200}  alt="No  Found"
                                height={100} />
                              )
                            ) : (
                              ""
                            )}
                            <FileUpload
                              onFileChange={(val) =>
                                handleSetValueOfBackendData(
                                  val,
                                  "section6",
                                  "sectionArr",
                                  seINdex,
                                  "image"
                                )
                              }
                            />
                          </div>
                        </>
                      ))}
                  </div>
                  <hr />
                
                  <hr />
                  <div className="row my-4">
                    <h5 className="blue-1 mb-4">Section 8</h5>
                    <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData.section8.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section8",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>

                    {backendData.section8.sectionArr &&
                      backendData.section8.sectionArr.length > 0 &&
                      backendData.section8.sectionArr.map((sect, seINdex) => (
                        <>
                          <div className="col-4">
                            <label>
                              Name <span className="red">*</span>
                            </label>
                            <input
                              value={sect.name}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                    event.target.value,
                                  "section8",
                                  "sectionArr",
                                  seINdex,
                                  "name"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-4">
                            <label>
                              URL <span className="red">*</span>
                            </label>
                            <input
                              value={sect.url}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                    event.target.value,
                                  "section8",
                                  "sectionArr",
                                  seINdex,
                                  "url"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-4">
                            <label>Image</label>
                            <br />

                            {sect.image != "" ? (
                              sect.image?.includes("base64") ? (
                                <img
                                  src={sect.image}
                                  width={200}  alt="No  Found"
                                  height={100}
                                />
                              ) : (
                                <img src={generateFilePath(sect.image)}      width={200}  alt="No  Found"
                                height={100} />
                              )
                            ) : (
                              ""
                            )}
                            <FileUpload
                              onFileChange={(val) =>
                                handleSetValueOfBackendData(
                                  val,
                                  "section8",
                                  "sectionArr",
                                  seINdex,
                                  "image"
                                )
                              }
                            />
                          </div>
                        </>
                      ))}
                  </div>
                  <hr />
                  <div className="row my-4">
                    <h5 className="blue-1 mb-4">Section 9</h5>
                    <div className="col-12">
                      <label>
                        Heading <span className="red">*</span>
                      </label>
                      <input
                        value={backendData.section9.heading}
                        onChange={(event) =>
                          handleSetValueOfBackendData(
                            event.target.value,
                            "section9",
                            "heading"
                          )
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>

                    {backendData.section9.sectionArr &&
                      backendData.section9.sectionArr.length > 0 &&
                      backendData.section9.sectionArr.map((sect, seINdex) => (
                        <>
                          <div className="col-4">
                            <label>
                              Name <span className="red">*</span>
                            </label>
                            <input
                              value={sect.name}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                    event.target.value,
                                  "section9",
                                  "sectionArr",
                                  seINdex,
                                  "name"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-4">
                            <label>
                              URL <span className="red">*</span>
                            </label>
                            <input
                              value={sect.url}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                    event.target.value,
                                  "section9",
                                  "sectionArr",
                                  seINdex,
                                  "url"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-4">
                            <label>Image</label>
                            <br />

                            {sect.image != "" ? (
                              sect.image?.includes("base64") ? (
                                <img
                                  src={sect.image}
                                  width={200}  alt="No  Found"
                                  height={100}
                                />
                              ) : (
                                <img src={generateFilePath(sect.image)}      width={200}  alt="No  Found"
                                height={100} />
                              )
                            ) : (
                              ""
                            )}
                            <FileUpload
                              onFileChange={(val) =>
                                handleSetValueOfBackendData(
                                  val,
                                  "section9",
                                  "sectionArr",
                                  seINdex,
                                  "image"
                                )
                              }
                            />
                          </div>
                        </>
                      ))}
                  </div>
                  <hr />

                  <hr />
               
                  <div className="row my-4">
                    <h5 className="blue-1 mb-4">Section 10</h5>

                    {backendData.section10.sectionArr &&
                      backendData.section10.sectionArr.length > 0 &&
                      backendData.section10.sectionArr.map((sect, seINdex) => (
                        <>
                          <div className="col-6">
                            <label>
                              URL <span className="red">*</span>
                            </label>
                            <input
                              value={sect.url}
                              onChange={(event) =>
                                handleSetValueOfBackendData(
                                    event.target.value,
                                  "section10",
                                  "sectionArr",
                                  seINdex,
                                  "url"
                                )
                              }
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-6">
                            <label>Image</label>
                            <br />

                            {sect.image != "" ? (
                              sect.image?.includes("base64") ? (
                                <img
                                  src={sect.image}
                                  width={200}  alt="No  Found"
                                  height={100}
                                />
                              ) : (
                                <img src={generateFilePath(sect.image)}       width={200}  alt="No  Found"
                                height={100}/>
                              )
                            ) : (
                              ""
                            )}
                            <FileUpload
                              onFileChange={(val) =>
                                handleSetValueOfBackendData(
                                  val,
                                  "section10",
                                  "sectionArr",
                                  seINdex,
                                  "image"
                                )
                              }
                            />
                          </div>
                        </>
                      ))}
                  </div> */}
                  <div className="col-12">
                    <CustomButton
                      btntype="button"
                      ClickEvent={handleSubmit}
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="Save"
                    />
                  </div>
                </DashboardBox>
              </div>

            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default Homepage;
