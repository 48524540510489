import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/cardLink";


export const addCardLink = (formData) => {
  return axiosApiInstance.post(serverUrl + "/addCardLink", formData);
};

export const getCardLink = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getCardLink?${query}`);
};
export const updateCardLink = (id, obj) => {
  return axiosApiInstance.patch(`${serverUrl}/updateCardLink/${id}`, obj);
};



export const getBySlug = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getBySlug/${id}`);
};
export const deleteCardLink = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
