import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as SellerCategory from "../../actions/SellerCategory/SellerCategory.actions";

const initialState = {
  sellerCategories: null,
  sellerCategoryObj: null,
  loading: false,
  error: null,
};

export const SellerCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SellerCategory.SELLER_CATEGORY_ADD:
      return {
        ...state,
        loading: true,
      };
    case SellerCategory.SELLER_CATEGORY_ADD_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case SellerCategory.SELLER_CATEGORY_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SellerCategory.GET_ALL_SELLER_CATEGORYS:
      return {
        ...state,
        loading: true,
      };
    case SellerCategory.GET_ALL_SELLER_CATEGORYS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sellerCategories: action.payload.data,
      };
    case SellerCategory.GET_ALL_SELLER_CATEGORYS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SellerCategory.DELETE_SELLER_CATEGORY_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case SellerCategory.DELETE_SELLER_CATEGORY_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SellerCategory.DELETE_SELLER_CATEGORY_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SellerCategory.UPDATE_SELLER_CATEGORY_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case SellerCategory.UPDATE_SELLER_CATEGORY_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SellerCategory.UPDATE_SELLER_CATEGORY_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SellerCategory.SET_SELLER_CATEGORY_OBJ:
      return {
        ...state,
        loading: true,
      };
    case SellerCategory.SET_SELLER_CATEGORY_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        sellerCategoryObj: action.payload.data,
        loading: false,
        error: null,
      };
    case SellerCategory.SET_SELLER_CATEGORY_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
