import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { CATEGORYGet } from "../../redux/actions/Category/Category.actions";

import {
  PRODUCTDelete,
  PRODUCTGet,
  SetPRODUCTObj,
} from "../../redux/actions/Product/Product.actions";
import { usersGet } from "../../redux/actions/Users/users.actions";
import {
  approveProductsInBulk,
  deleteProductsInBulk,
  getAdminAllProducts,
  getProducts,
  sampleProductUpdateFileDownload,
  unapproveProductsInBulk,
} from "../../services/product.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import { AddModal, EditModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
import { generateFilePath, productMainStatusObj, productStatusArr } from "../Utility/utils";

function DesignList() {
  const dispatch = useDispatch();
  const categoryArrFromRedux = useSelector(
    (state) => state.category.categories
  );
  const navigate = useNavigate();
  const productArr = useSelector((state) => state.product.products);
  const [displayProductArr, setDisplayProductArr] = useState([]);
  const [mainCategoryArr, setMainCategoryArr] = useState([]);

  const [filterStatus, setFilterStatus] = useState(null);
  const [mainStatus, setMainStatus] = useState(null);

  const [page, setPage] = useState(1);
  const [categoryId, setCategoryId] = useState({
    label:"None",value:""
  });
  const [perPage, setPerPage] = useState(10);

  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [query, setQuery] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const userArr = useSelector((state) => state.users.users);
  const [selectedSellerObj, setSelectedSellerObj] = useState(null);
  const [displaySellersArr, setDisplaySellersArr] = useState([]);
  const getSellerUsers = () => {
    dispatch(usersGet("role=SELLER"));
  };

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  useEffect(() => {
    getSellerUsers();
    dispatch(CATEGORYGet());

  }, []);

    useEffect(() => {
    if (categoryArrFromRedux) {
      setMainCategoryArr([...categoryArrFromRedux]);
    }
  }, [categoryArrFromRedux]);
  const handleGetProducts = async () => {
    try {
      let queryOf = `page=${page}&perPage=10&isDesign=true`;

      console.log(searchStr,"searchStrsearchStr")
      if (searchStr != "") {
        queryOf = `${queryOf}&q=${searchStr}`;
      }

      if(categoryId && categoryId?.value !=""){
          queryOf = `${queryOf}&categoryId=${categoryId?.value}`;
      }
      // console.log(queryOf,"queryOfqueryOfqueryOfqueryOf")
      // dispatch(PRODUCTGet(queryOf));

      let { data: get } = await getAdminAllProducts(queryOf);
      console.log(get, "get =====");
      if (get.success) {
        if (get.data) {
          setDisplayProductArr([
            ...get?.data.map((el) => {
              el.checked = false;
              return el;
            }),
          ]);
        } else {
          setDisplayProductArr([]);
        }
        setTotalPages(get?.totalPages);
        setTotalCount(get?.totalCount);
        setPage(get?.page);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const [displayButtons, setDisplayButtons] = useState(false);

  useEffect(() => {
    if (userArr && userArr.length > 0) {
      setDisplaySellersArr([
        ...userArr.map((el) => {
          el.label = el?.name;
          el.value = el?._id;
          return el;
        }),
      ]);
    }
  }, [userArr]);

  const handleEdit = (row) => {
    // dispatch(SetPRODUCTObj(row));
  };

  const handleDeleteById = (id) => {
    dispatch(PRODUCTDelete(id));
  };

  useEffect(() => {
    setDisplayProductArr([]);
    handleGetProducts();
  }, [page, perPage, searchStr,categoryId]);

  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalData, setModalData] = useState({});
  const [ModalBox, setModalBox] = useState(false);

  const handleCheckProduct = (row, index, e) => {
    let tempArr = displayProductArr.map((el) => {
      if (row._id == el._id) {
        el.checked = e.target.checked;
      }
      return el;
    });
    handleGetProductCheckedStatus(tempArr);
    setDisplayProductArr([...tempArr]);
  };

  const handleGetProductCheckedStatus = (tempArr) => {
    if (tempArr.some((el) => el.checked == true)) {
      setDisplayButtons(true);
    } else {
      setDisplayButtons(false);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      let selectedArr = displayProductArr
        .filter((el) => el.checked)
        .map((el) => {
          let obj = {
            productId: el._id,
          };
          return obj;
        });
      if (!selectedArr.length > 0) {
        toastError("Please select atleast one product to perform this action");
        return;
      }
      let { data: res } = await deleteProductsInBulk(selectedArr);
      if (res.message) {
        toastSuccess(res.message);
        handleGetProducts();
      }
    } catch (e) {
      toastError(e);
    }
  };

  const handleSetSelectedAsActive = async () => {
    try {
      let selectedArr = displayProductArr
        .filter((el) => el.checked)
        .map((el) => {
          let obj = {
            productId: el._id,
          };
          return obj;
        });
      if (!selectedArr.length > 0) {
        toastError("Please select atleast one product to perform this action");
        return;
      }
      let { data: res } = await approveProductsInBulk(selectedArr);
      // console.log(res, "response");
      if (res.message) {
        toastSuccess(res.message);
        handleGetProducts();
      }
    } catch (e) {
      toastError(e);
    }
  };
  const handleSetSelectedAsInActive = async () => {
    try {
      let selectedArr = displayProductArr
        .filter((el) => el.checked)
        .map((el) => {
          let obj = {
            productId: el._id,
          };
          return obj;
        });
      if (!selectedArr.length > 0) {
        toastError("Please select atleast one product to perform this action");
        return;
      }

      let { data: res } = await unapproveProductsInBulk(selectedArr);
      if (res.message) {
        toastSuccess(res.message);
        handleGetProducts();
      }
    } catch (e) {
      toastError(e);
    }
  };

  const handleClearSelection = () => {
    let tempArr = displayProductArr.map((el) => {
      el.checked = false;
      return el;
    });
    setDisplayProductArr(tempArr);
  };

  const product_sale_columns = [
    {
      name: "SL",
      cell: (row, index) => (
        <input
          key={index}
          type="checkbox"
          checked={row.checked}
          onChange={(e) => handleCheckProduct(row, index, e)}
        />
      ),
      sortable: true,
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      searchable: true,

      width: "15%",
    },
    {
      name: "SKU",
      selector: (row) => row?.sku,
      searchable: true,
      width: "15%",
    },
    {
      name: "Product Code",
      selector: (row) => row?.productCode,
      searchable: true,

      width: "15%",
    },

    {
      name: "Image",
      grow: 0,
      cell: (row) => (
        <a href={row?.imageArr[0]?.image} target="_blank">
          <img
            height="84px"
            width="56px"
            alt={
              row?.imageArr[0]?.imageAlt ? row?.imageArr[0]?.imageAlt : "ALT"
            }
            src={generateFilePath(row?.mainImage)}
          />
        </a>
      ),
      width: "15%",
    },

    {
      name: "Action",
      width: "15%",
      cell: (row) => (
        <>
          <ActionIcon
             Uniquekey={row.id}
             remove
             deletePath="/Product/Product-List"
             onDeleteClick={() => handleDeleteById(row._id)}
             isRedirected={true}
             onEditClick={() => {
           
             }}
             istarget={true}
             edit
             editPath={`/Product/Update-Product?slug=${row._id}`}
          />
        </>
      ),
    },
    // {
    //   name: "Clone",
    //   width: "15%",
    //   cell: (row) => (
    //     <>
    //       <CustomButton
    //         btntype="button"
    //         isLink
    //         path={`/Product/clone-Product`}
    //         edit
    //         ClickEvent={() => {
    //           dispatch(SetPRODUCTObj(row));
    //         }}
    //         iconName="fa-solid fa-pen-to-square"
    //         btnName="Clone Product"
    //       />
    //     </>
    //   ),
    // },
  ];

  function filter(el) {
    let chk = false;
    let tempColumns = [...product_sale_columns.filter((el) => el?.searchable)];
    // console.log(tempColumns, "@@S");
    if (!tempColumns?.length) chk = true;
    tempColumns.forEach((elx) => {
      let val = elx?.selector(el);
      if (val) {
        if (typeof val == "string" || typeof val == "number") {
          if (`${val}`.toLowerCase().includes(searchStr.toLowerCase()))
            chk = true;
        }
      }
    });
    return chk;
  }

  const handleChange = (value) => {
    setPage(1);
    setSearchStr(value);
  };

  const handleStatusFilter = (obj) => {
    // setSelectedSellerObj(null);
    setFilterStatus(obj);
    if (selectedSellerObj && selectedSellerObj?.value != "") {
      dispatch(
        PRODUCTGet(`status=${obj.value}&sellerId=${selectedSellerObj?.value}`)
      );
    } else {
      dispatch(PRODUCTGet(`status=${obj.value}`));
    }
  };

  const handleSellerFilter = (obj) => {
    setSelectedSellerObj(obj);
    if (filterStatus && filterStatus?.value != "") {
      dispatch(
        PRODUCTGet(`sellerId=${obj.value}&status=${filterStatus?.value}`)
      );
    } else {
      dispatch(PRODUCTGet(`sellerId=${obj.value}`));
    }
  };

  const handleMainStatusFilter = (obj) => {
    // setSelectedSellerObj(null);
    setMainStatus(obj);

    let filterStr = `active=${obj.value == productMainStatusObj.Active}`;

    if (selectedSellerObj && selectedSellerObj?.value != "") {
      filterStatus = filterStatus + `&sellerId=${selectedSellerObj?.value}`;
    }
    if (filterStatus && filterStatus?.value != "") {
      filterStatus = filterStatus + `&status=${filterStatus?.value}`;
    }
    dispatch(PRODUCTGet(filterStr));
  };

  const handleRowSelection = (e) => {
    let tempArr = e.selectedRows;
    let tempDisplayProductArr = [...displayProductArr];
    tempDisplayProductArr = tempDisplayProductArr.map((el) => ({
      ...el,
      checked: tempArr.some((elx) => elx._id == el._id),
    }));
    // console.log(tempDisplayProductArr, "ASDSDA");
    // handleGetProductCheckedStatus(tempDisplayProductArr);
    setDisplayProductArr([...tempDisplayProductArr]);
  };

  const handleFilterReset = () => {
    setSelectedSellerObj(null);
    setMainStatus(null);
    setFilterStatus(null);
    handleGetProducts();
  };

  const handleSampleFileDownload = async () => {
    try {
      if (displayProductArr && displayProductArr?.length > 0) {
        let encoded = displayProductArr.map((el) => ({
          productId: el._id,
          variantId: el.variantId,
        }));
        await sampleProductUpdateFileDownload(JSON.stringify(encoded));
      } else {
        toastError({ message: "Please Select Product" });
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleDisplayProductSelectAll = () => {
    let tempArr = [...displayProductArr];
    tempArr = tempArr.map((el) => ({ ...el, checked: true }));
    handleGetProductCheckedStatus(tempArr);
    setDisplayProductArr([...tempArr]);
  };
  const handleDisplayProductUnSelectAll = () => {
    let tempArr = [...displayProductArr];
    tempArr = tempArr.map((el) => ({ ...el, checked: false }));
    handleGetProductCheckedStatus(tempArr);

    setDisplayProductArr([...tempArr]);
  };






  return (
    <main>
      <AddModal
        ModalBox={ModalBox}
        setModalBox={setModalBox}
        name={ModalName}
        data={ModalData}
        ModalType={ModalType}
      />
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1">Deisgn List</h5>
              </div>
             
              <DashboardTable>
              <DataTable
                  columns={product_sale_columns}
                  data={
                    displayProductArr && displayProductArr.length > 0
                      ? displayProductArr
                      : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
      <EditModal
        ModalBox={ModalBox}
        data={ModalData}
        setModalBox={setModalBox}
        name={ModalName}
        ModalType={ModalType}
      />
    </main>
  );
}

export default DesignList;
