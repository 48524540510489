import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import CustomButton from "../Utility/Button";
import tabClick from "../Utility/TabClick";
import { DashboardBox, DashboardChart, DashboardTable } from "../Utility/DashboardBox";
import { fetchToken } from "../../firebase";
import { registerFcmToken } from "../../services/users.service";
import { getInsightsConnectionApi } from "../../services/connection.service";
import { toastError } from "../Utility/ToastUtils";
import { useLocation } from "react-router-dom";
import { images } from "../Images/Images";
import { generateFilePath } from "../Utility/utils";
function Insight() {

  const location = useLocation();

  const [connection, setConnection] = useState("");
  const [linkArr, setlinkArr] = useState();
  const [cardId, setcardId] = useState("");
  const [date, setdate] = useState();
  const [tapThroughRate, setTapThroughRate] = useState(0);
  const [filter, setfilter] = useState([
    {
      name: "All",
      active: true,
      type: "all",
    },
    {
      name: "Today",
      active: false,
      type: "today",
    },
    {
      name: "Previous Week",
      active: false,
      type: "week",
    },
    {
      name: "Previous Month",
      active: false,
      type: "month",
    },
  
  ]);

  const product_columns = [
    {
      name: "Images",
      selector: (row) =>   <img height="84px" width="56px" alt={generateFilePath(row.image)} src={generateFilePath(row.image)} /> ,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Value ",
      selector: (row) => row.value?.length > 20 ? `${row.value?.slice(0,20)}...` : row.value ,
    },
    {
      name: "Tap Count ",
      selector: (row) => row.tapsCount,
    },
  ];
  
  const HandleGetUser = async () => {
    try {

      let query = 'filter='+date
        let { data: res } = await getInsightsConnectionApi(cardId,query)
        if (res.data) {
          setConnection(res.data)

          let ptap  =  res?.data?.profileTapCount ? res?.data?.profileTapCount :  1;
          let ltap =  res?.data?.linkTapCount ? res?.data?.linkTapCount :  0;
    
            let ttap =(ltap/ptap)*100;
            setTapThroughRate(ttap.toFixed(2))
            if(res.data?.linkArr?.length > 0){
              setlinkArr(res.data?.linkArr[0]?.cardLinkArr)
            }
        }
    }
    catch (err) {
        toastError(err)
    }
  }
  useEffect(() => {
    // console.log(location, "location")
    if (location.pathname && location.pathname.split("/")[2]) {
       setcardId(location.pathname.split("/")[2])
        // HandleGetUser(location.pathname.split("/")[2])
    }

}, [location.pathname])


useEffect(() => {

  let filterActiveObj = filter.find((el) => el.active ==true);
  if(filterActiveObj && filterActiveObj?.type){
    setdate(filterActiveObj?.type)
  }
  console.log(filterActiveObj,"filterActiveObjfilterActiveObj")
}, [filter])

useEffect(() => {
  if(date){
    HandleGetUser()
  }
}, [date])



  // ================================================================================================================






  // ================================================================================================================

  return (
    <main>
      <section className="dashboard-head mb-5">
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <h5 className="blue-1 mb-0">Summary</h5>
          <ul className="dashboard-filter filters">
            {filter.map((item, i) => {
              return (
                <li key={`${item.type}_${i}`}>
                  <CustomButton navPills btnName={item.name} changeClass="filtering" pillActive={item.active ? true : false} data-type={item.type} ClickEvent={() => tabClick(i, filter, setfilter)} />
                </li>
              );
            })}
          </ul>
        </div>
      </section>

      <section className="mb-5">
        <div className="container-fluid">
          <div className="row">
                <div className="col-12 col-lg-3" >
                  <DashboardBox className="dashboard-summary">
                    <h5 className="blue-1">Connection Count</h5>
                    <h4 className="text-dark mb-0">{connection?.connectTapCount ? connection?.connectTapCount: 0}</h4>
                  </DashboardBox>
                </div>

                <div className="col-12 col-lg-3" >
                  <DashboardBox className="dashboard-summary">
                    <h5 className="blue-1">Link Count</h5>
                    <h4 className="text-dark mb-0">{connection?.linkTapCount ? connection?.linkTapCount: 0}</h4>
                  </DashboardBox>
                </div>

                <div className="col-12 col-lg-3" >
                  <DashboardBox className="dashboard-summary">
                    <h5 className="blue-1">Profile Count</h5>
                    <h4 className="text-dark mb-0">{connection?.profileTapCount ? connection?.profileTapCount: 0}</h4>
                  </DashboardBox>
                </div>


                <div className="col-12 col-lg-3" >
                  <DashboardBox className="dashboard-summary">
                    <h5 className="blue-1">Tap thourgh rate</h5>
                    <h4 className="text-dark mb-0">{tapThroughRate}</h4>
                  </DashboardBox>
                </div>
          </div>
        </div>
      </section>

     

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6 mb-5">
              <DashboardTable>
                <h5 className="blue-1 mb-4">Link Tap</h5>
                <DataTable columns={product_columns} data={linkArr} />
              </DashboardTable>
            </div>
          
          </div>
        </div>
      </section>
    </main>
  );
}

export default Insight;
