import React from "react";
import { images } from "../Images/Images";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import logo from "../../assets/images/logo.png"

function SideBar({ style }) {
  let role = useSelector((state) => state.auth.role);

  let location = useLocation();
  const [sidebar_item, setsidebar_item] = useState([
    {
      isrotated: true,
      active: true,
      name: "dashboard",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Dashboard",
      icon: "ion-grid",
      children: [],
    },
    {
      isrotated: true,
      active: false,
      name: "Cards",
      path: "/User/cards",
      icon: "fa-solid fa-credit-card",
      roleArr: ["ADMIN", "SUBADMIN","COMPANY"],
      children: [{
        active: false,
        name: "Blank Cards",
        path: "/User/blank-cards",
        icon: "fa-solid fa-credit-card",
        roleArr: ["ADMIN", "SUBADMIN"],
      },
      {
        active: false,
        name: "Cards",
        path: "/User/cards",
        icon: "fa-solid fa-credit-card",
        roleArr: ["ADMIN", "SUBADMIN","COMPANY"],
      }],
    },
    {
      isrotated: true,
      active: false,
      name: "Users",
      path: "/User/users",
      icon: "fa-solid fa-users",
      roleArr: ["ADMIN", "SUBADMIN","COMPANY"],
      children: [],
    },
  
      {
      isrotated: true,
      active: false,
      name: "Cardlinks",
      path: "Cardlink/card-links",
      icon: "fa-solid fa-users",
      roleArr: ["ADMIN", "SUBADMIN"],
      children: [
      {
      active: false,
      name: "Category",
      path: "Cardlink/category",
      icon: "fa-solid fa-users",
      roleArr: ["ADMIN", "SUBADMIN"],
      children: [],

    },{
      active: false,
      name: "links",
      path: "Cardlink/card-links",
      icon: "fa-solid fa-users",
      roleArr: ["ADMIN", "SUBADMIN"],
      children: [],
    }
],


    },
    {
      isrotated: true,
      active: true,
      name: "verfication",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Verfication/verfication",
      icon: "ion-grid",
      children: [],
    },

    {
      isrotated: true,
      active: true,
      name: "DesignList",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Product/Design-List",
      icon: "ion-grid",
      children: [],
    },
    {
        isrotated: false,
        active: false,
        name: "Products",
       
        icon: "fa-brands fa-product-hunt",
        roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
        children: [
          {
            name: "Category",
            roleArr: ["ADMIN", "SUBADMIN"],
            path: "/Product/Category",
            active: false,
          },
  
          {
            name: "Attribute",
            path: "/Product/Attribute",
            roleArr: ["ADMIN", "SUBADMIN"],
            active: false,
          },
  
          {
            name: "Bulk Product Upload",
            path: "/Product/Bulk-Product-Upload",
            roleArr: ["ADMIN", "SUBADMIN", "SELLER"],
            active: false,
          },
          {
            name: "Products",
            path: "/Product/Product-List",
            roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
            active: false,
          },
          {
            name: "Product Review",
            path: "/Review/Product-Review",
            roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
            active: false,
          },
  
        ],
      },
    
      {
        isrotated: false,
        active: false,
        name: "Order Manage",
      
        roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
        icon: "ion-android-cart",
        children: [
          {
            name: "Total Order",
            path: "/Order/Total-Order",
            roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
            active: false,
          },
        
        ],
      },
      {
        isrotated: false,
        active: false,
        name: "Coupon",
        roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      
        icon: "fa-solid fa-gift",
        children: [
          {
            name: "Coupon",
            path: "/Coupon/Coupon",
            roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
            active: false,
          },
  
      
        ],
      },
      {
        isrotated: false,
        active: false,
        name: "Frontend CMS",
    
        roleArr: ["ADMIN", "SUBADMIN"],
        icon: "fa-solid fa-user",
        children: [
          {
            name: "Banners",
            path: "/Banners",
            roleArr: ["ADMIN", "SUBADMIN"],
            active: false,
          },
          {
            name: "Clients",
            path: "/Clients",
            roleArr: ["ADMIN", "SUBADMIN"],
            active: false,
          },

          {
            name: "Testimonials",
            path: "/Testimonials",
            roleArr: ["ADMIN", "SUBADMIN"],
            active: false,
          },
          {
            name: "Homepage",
            path: "/HomepageCMS",
            roleArr: ["ADMIN", "SUBADMIN"],
            active: false,
          },
          {

            name: "Privacy-Policy",
            path: "/Privacy-Policy",
            roleArr: ["ADMIN", "SUBADMIN"],
            active: false,
          },
          {
            name: "Refund-Policy",
            path: "/Refund-Policy",
            roleArr: ["ADMIN", "SUBADMIN"],
            active: false,
          },
          {
            name: "Terms-Conditions",
            path: "/Terms-Conditions",
            roleArr: ["ADMIN", "SUBADMIN"],
            active: false,
          },
         
  
          {
            active: false,
            name: "Seo",
            roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
            path: "/Seo/View-Seo",
            icon: "fa fa-list",
           
          },
        ],
      },
    
      {
        isrotated: false,
        active: false,
        name: "Blog",
        roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      
        icon: "fa-solid fa-users",
        children: [
          {
            name: "Blog",
            path: "/Blog/post",
            roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
            active: false,
          },
          {
            name: "Blog category",
            path: "/Blog/Category",
            roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
            active: false,
          },
        ],
      },
      {
        isrotated: false,
        active: false,
        name: "Query",
        roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
   
        icon: "fa-solid fa-cog",
        children: [
          {
            isrotated: true,
            active: true,
            name: "Subscriber",
            roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
            path: "/Subscriber",
            icon: "fa-solid fa-user",
          },
          {
            name: "Contact",
            path: "/Contact",
            roleArr: ["ADMIN", "SUBADMIN"],
            active: false,
          },
        ],
      },
      {
        isrotated: false,
        active: false,
        name: "Settings",
        roleArr: ["SELLER", "SUBADMIN", "ADMIN"],
    
        icon: "fa-solid fa-user",
        children: [
          {
            name: "Site Setting",
            path: "/System-Setting/Setting",
            roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
            active: false,
          },
        ],
      },
  ]);

  const isRotating = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (index === i) {
        el.isrotated = !el.isrotated;
        el.active = true;
      } else {
        el.active = false;
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  const childActive = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (el.children && el.children.length > 0) {
        el.children.map((item, childIndex) => {
          if (childIndex === i) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  return (
    <div id="sidebar">
      <div className="main-logo" style={style ? { padding: "26px 10px", height: "auto" } : {}}>
        {style ? <img src={images?.favicon} alt="" /> : <img src={images?.logo} alt="" className="img-fluid" />}
      </div>
      <ul className="sidebar-menu" id="sidebarMenu">
        {sidebar_item &&
          sidebar_item?.map((item, i) => {
            if (typeof array === "undefined" && item?.children && item?.children?.length === 0 && item?.roleArr.some((el) => `${el}`.toLowerCase() == `${role}`.toLowerCase())) {
              return (
                <li key={`sidebar_item_${i}`}>
                  <Link to={item?.path} className={item?.active ? "active" : ""} onClick={() => isRotating(i)}>
                    <i className={item?.icon}></i>
                    {!style && <p className="mb-0">{item?.name}</p>}
                  </Link>
                </li>
              );
            } else {
              if (item?.roleArr && item?.roleArr?.length > 0 && item?.roleArr.some((el) => `${el}`.toLowerCase() == `${role}`.toLowerCase())) {
                return (
                  <li key={`sidebar_item_${i}`}>
                    <Link
                      to={`#sidebar_item_children_${i}`}
                      className={item?.active || location === item?.path ? "active" : ""}
                      data-bs-toggle="collapse"
                      aria-expanded={item?.active}
                      aria-controls={`sidebar_item_children_${i}`}
                      role="button"
                      onClick={() => isRotating(i)}
                    >
                      <i className={item.icon}></i>
                      {!style && (
                        <p className="mb-0">
                          {item?.name}
                          {item?.isrotated ? <i className="ion-arrow-up-b pe-3"></i> : <i className="ion-arrow-down-b pe-3"></i>}
                        </p>
                      )}
                    </Link>
                    {!style && (
                      <ul className="collapse" id={`sidebar_item_children_${i}`} data-bs-parent="#sidebarMenu">
                        {item?.children?.map((child, index) => {
                          if (child?.roleArr && child?.roleArr?.length > 0 && child?.roleArr?.some((el) => `${el}`?.toLowerCase() == `${role}`?.toLowerCase())) {
                            return (
                              <li key={`${child?.name}_${index}`}>
                                <Link to={child?.path} className={child?.active || location === child?.path ? "active" : ""} onClick={() => childActive(index)}>
                                  {child?.name}
                                </Link>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    )}
                  </li>
                );
              }
            }
          })}
      </ul>
    </div>
  );
}

export default SideBar;
